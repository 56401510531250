import React, { useEffect, useState } from "react";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import useRoutesHelper from "../hooks/useRoutesHelper";
import { useNavigate } from "react-router-dom";
import { toServerUrl } from "../helpers/assetHelper";
import { useSelector } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";

function EventMainPage() {
  const { currentUrl } = useRoutesHelper();
  const navigate = useNavigate();

  const {
    events: {
      eventData: { data: currentEvent, isLoading: isLoadingEvent },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  const [currentEventPage, setCurrentEventPage] = useState("");

  useEffect(() => {
    const paramsStartingIndex = currentUrl.indexOf("/");

    const getSlicedString = () => {
      if (paramsStartingIndex > 0) {
        return currentUrl.substring(paramsStartingIndex + 1);
      }
      return currentUrl;
    };

    setCurrentEventPage(getSlicedString());
  }, [currentUrl]);

  if (!currentEventPage) {
    return;
  }

  if (isLoadingEvent) {
    return (
      <div className="min-h-screen pt-[55px] flex items-center justify-center">
        <SpinnerCircularFixed
          size={100}
          thickness={157}
          speed={300}
          color="#44bb92"
          secondaryColor="rgba(0, 0, 0, 0.44)"
        />
      </div>
    );
  }

  if (!currentEvent) {
    navigate("/*");
    return;
  }

  const {
    picture: eventPicture,
    title: eventTitle,
    description: eventDescription,
  } = currentEvent;

  return (
    <div className="min-h-screen pt-[55px]">
      <HeroComponent
        height={650}
        title={eventTitle}
        backgroundImage={toServerUrl(eventPicture)}
      />
      {currentEvent && (
        <div className="p-[15px] sm:p-[20px] lg:p-[40px] max-w-7xl mx-auto">
          <div className="bg-gray-50   flex flex-col justify-center items-center lg:justify-between rounded-xl p-2 sm:p-10 ">
            <div className="w-full flex flex-col justify-between">
              <p className="text-sm xl:text-base font-medium ">
                {eventDescription}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventMainPage;
