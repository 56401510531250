import React from "react";
import Content from "../Content";

function ImageAndContentComponent({
  title,
  description,
  descriptions,
  descriptionClass,
  buttonText,
  sectionImage,
  type,
  contentType,
  onClick,
  wrapperClassName,
}) {
  const getSectionImage = () => {
    if (sectionImage) {
      return sectionImage;
    }

    return "https://media.istockphoto.com/id/1138288756/photo/light-lime-defocused-blurred-motion-abstract-background.webp?b=1&s=170667a&w=0&k=20&c=vXukPMnWmjgNeOnvB7Bs7kneUK5BL-m-huCT84H4oNw=";
  };

  return (
    <div
      className={`${wrapperClassName} p-[16px] sm:p-[40px] lg:p-[60px] flex flex-col justify-between items-center ${
        type === "reverse"
          ? "lg:items-center lg:flex-row-reverse"
          : "lg:items-center lg:flex-row"
      }  `}
    >
      <Content
        wrapperClassName={` w-11/12 lg:w-1/3 h-full ${
          type === "reverse" ? "" : "lg:mr-16"
        }`}
        contentType={contentType}
        title={title}
        description={description}
        descriptions={descriptions}
        buttonText={buttonText}
        onClick={onClick}
        descriptionClass={descriptionClass}
      />

      <img
        alt="2AFarm"
        src={`${getSectionImage()}`}
        className={`${type === "reverse" ? "lg:mr-16" : ""}  z-10 relative
        before:content-['']
        before:absolute
        before:inset-0
        before:block
        before:bg-gradient-to-tr
        before:from-gray-700
        before:to-gray-800
        before:opacity-40
        before:z-[-5] w-full lg:w-4/6 h-[300px] md:h-[450px] mt-5 lg:mt-0 object-cover pointer-events-none`}
      />
    </div>
  );
}

export default ImageAndContentComponent;
