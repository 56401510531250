import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { toAbsoluteUrl, toServerUrl } from "../helpers/assetHelper";
import EventsComponent from "../components/PagesComponents/EventsComponent";
import ImageAndContentComponent from "../components/PagesComponents/ImageAndContentComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventsData } from "../redux/api/events.api";
import { setEventData } from "../redux/reducer/events.reducer";
import { SpinnerCircularFixed } from "spinners-react";

function Events() {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();

  const {
    events: {
      eventsData: {
        data: { data: eventsData },
        isLoading: isLoadingEvent,
      },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  useEffect(() => {
    dispatch(fetchEventsData());
  }, [dispatch]);

  const handleGoToEventPage = (e, event) => {
    e.preventDefault();
    dispatch(setEventData(event));
    navigate(`/events/${event.id}`);
  };

  return (
    <div className="min-h-screen pt-[55px]">
      <HeroComponent
        height={650}
        title={translate("hero_events_title")}
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0525.jpg")}
      />
      {isLoadingEvent && (
        <div className="pb-[300px] pt-[55px] flex items-start justify-center ">
          <SpinnerCircularFixed
            size={100}
            thickness={157}
            speed={300}
            color="#44bb92"
            secondaryColor="rgba(0, 0, 0, 0.44)"
          />
        </div>
      )}
      {!isLoadingEvent &&
        eventsData.map((event, idx) => {
          if (idx % 2) {
            return (
              <ImageAndContentComponent
                key={event.id}
                type="reverse"
                title={event.title}
                description={event.description}
                descriptionClass="line-clamp-4"
                sectionImage={toServerUrl(event.picture)}
                buttonText="AFLA MAI MULT"
                onClick={(e) => handleGoToEventPage(e, event)}
              />
            );
          }

          return (
            <EventsComponent
              type="reverse"
              key={event.id}
              title={event.title}
              description={event.description}
              backgroundImage={toServerUrl(event.picture)}
              wrapperClassName="my-5 sm:my-10 md:my-20"
              buttonText="AFLA MAI MULT"
              eventId={event.id}
              contentType=""
              contentBackground=""
              onClick={(e) => handleGoToEventPage(e, event)}
            />
          );
        })}

      <HeroComponent
        height={250}
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0525.jpg")}
        wrapperClassName="before:from-primary-color before:to-primary-color"
        url="/contact"
      />
    </div>
  );
}

export default Events;
