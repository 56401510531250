import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import SiteLang from "./SiteLang";
import { Link as ScrollLink } from "react-scroll";

import { useTranslation } from "react-i18next";
import KTSVG from "../utils/KTSVG";
import { Link } from "react-router-dom";

const navbarPages = [
  {
    name: "navbar_link_3",
    to: "/contact",
  },
  {
    name: "navbar_career_link",
    to: "/career",
  },
  {
    name: "navbar_events_link",
    to: "/events",
  },
  {
    name: "navbar_representatives_link",
    to: "/representatives",
  },
];

function Navbar({ navbarLinks, ordersButtonText }) {
  const { t: translate } = useTranslation();

  const [showNav, setShowNav] = useState(true);

  const handleShowNav = () => {
    setShowNav(!showNav);
  };

  return (
    <nav className="flex flex-row justify-between items-center font-semibold top-0 z-50 h-14 fixed w-full bg-white border-b border-gray-100 pl-3 xl:px-20">
      <Link to="/">
        <KTSVG className="w-[110px]" path="/assets/logo.svg" />
      </Link>

      <ul className="hidden xl:flex md:flex-row md:items-center">
        {navbarLinks.map((link) => {
          return (
            <li key={link.name}>
              <ScrollLink
                to={link.href}
                className="navbar-page-btn"
                spy={true}
                smooth={true}
                duration={500}
                offset={-55}
              >
                {link.name}
              </ScrollLink>
            </li>
          );
        })}

        <ul>
          {navbarPages.map((navbarPage) => {
            return (
              <Link
                key={navbarPage.to}
                to={navbarPage.to}
                className="navbar-page-btn"
              >
                {translate(navbarPage.name)}
              </Link>
            );
          })}
        </ul>
      </ul>

      <div className="flex justify-center items-center">
        {ordersButtonText && (
          <a
            target="_blank"
            rel="noreferrer"
            href="http://comenzi.2afarm.ro/"
            className="text-secondary-color border-x border-secondary-color px-3 py-3.5"
          >
            {ordersButtonText}
          </a>
        )}
        <SiteLang
          wrapperClassName="px-2 py-2 h-auto hidden xl:flex "
          dropdownClassName="flex flex-col mr-[80px] bg-primary-color bg-opacity-80 px-2 py-2  absolute right-0 top-[54px] cursor-pointer ease-out duration-500"
        />

        <div>
          <div onClick={handleShowNav} className="xl:hidden">
            {showNav ? (
              <AiOutlineMenu
                size={45}
                className="text-primary-color cursor-pointer px-3"
              />
            ) : (
              <AiOutlineClose
                size={45}
                className="text-primary-color cursor-pointer px-3"
              />
            )}
          </div>

          <div
            className={
              !showNav
                ? "bg-primary-color bg-opacity-90 backdrop-blur-sm ease-out duration-500 fixed right-0 top-[54px] h-full w-full  "
                : "ease-out duration-500 fixed left-[-100%]"
            }
          >
            <SiteLang
              onClickLanguage={handleShowNav}
              wrapperClassName="flex justify-end p-5 border-b border-gray-300"
              dropdownClassName="flex flex-row-reverse p-5 absolute right-[50px] top-0"
              dropdownImageClassName="ml-2"
            />

            <ul className="flex flex-col">
              {navbarLinks.map((link) => {
                return (
                  <li key={link.name} className="p-5 border-b border-gray-300">
                    <ScrollLink
                      onClick={handleShowNav}
                      to={link.href}
                      className=" text-gray-200  hover:text-primary-color ease-out duration-300"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-55}
                    >
                      {link.name}
                    </ScrollLink>
                  </li>
                );
              })}

              <ul className="flex flex-col">
                {navbarPages.map((navbarPage) => {
                  return (
                    <Link
                      key={navbarPage.to}
                      to={navbarPage.to}
                      className="p-5 border-b border-gray-300 cursor-pointer  text-gray-200 hover:text-primary-color ease-out duration-300"
                    >
                      {translate(navbarPage.name)}
                    </Link>
                  );
                })}
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
