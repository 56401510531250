export const departmentsConfig = [
  {
    id: 1,
    icon: "/assets/departmentsIcons/medicine.svg",
    pagePicture: "/assets/2aFarmPhotos/_SF_0031.jpg",
    title: "department_distribution_title",
    descriptions: [
      "department_distribution_description_1",
      "department_distribution_description_2",
      "department_distribution_description_3",
      "department_distribution_description_4",
      "department_distribution_description_5",
    ],
    url: "/distribution",
  },
  {
    id: 2,
    icon: "/assets/departmentsIcons/supply.svg",
    pagePicture: "/assets/2aFarmPhotos/_SF_0145.jpg",
    title: "department_logistics_title",
    descriptions: [
      "department_logistics_description_1",
      "department_logistics_description_2",
      "department_logistics_description_3",
      "department_logistics_description_4",
      "department_logistics_description_5",
      "department_logistics_description_6",
      "department_logistics_description_7",
      "department_logistics_description_8",
    ],
    url: "/logistics",
  },
  {
    id: 3,
    icon: "/assets/departmentsIcons/fleet.svg",
    pagePicture: "/assets/2aFarmPhotos/flota.jpg",
    title: "department_fleet_title",
    descriptions: [
      "department_fleet_description_1",
      "department_fleet_description_2",
      "department_fleet_description_3",
      "department_fleet_description_4",
    ],
    url: "/fleet",
  },
];
