import React from "react";
import useRoutesHelper from "../hooks/useRoutesHelper";
import ExternalNavbar from "../components/ExternalNavbar";
import { useTranslation } from "react-i18next";

function ShowNavbar() {
  const { currentUrl } = useRoutesHelper;
  const { t: translate } = useTranslation();

  return (
    <div>
      {currentUrl === "/" ? (
        ""
      ) : (
        <ExternalNavbar ordersButtonText={translate("navbar_orders")} />
      )}
    </div>
  );
}

export default ShowNavbar;
