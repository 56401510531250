import React from "react";

import "../lang/i18n";
import { useTranslation } from "react-i18next";

import { Element } from "react-scroll";

import Navbar from "../components/Navbar";
import FullBgWithContentComponent from "../components/PagesComponents/FullBgWithContentComponent";
import ImageAndContentComponent from "../components/PagesComponents/ImageAndContentComponent";
import HeroComponent from "../components/PagesComponents/HeroComponent";
import { toAbsoluteUrl } from "../helpers/assetHelper";
import HeroVideoComponent from "../components/PagesComponents/HeroVideoComponent";
import AboutCard from "../components/AboutCard";
import CompanyCard from "../components/CompanyCard";
import SliderWithText from "../components/SliderWithText";

import VideoAndContentComponent from "../components/PagesComponents/VideoAndContentComponent";
import { clientConfig } from "../configs/clientConfig";
import { useSelector } from "react-redux";

const aboutUsDescriptions = [
  "about_us_description_1",
  "about_us_description_2",
  "about_us_description_3",
  "about_us_description_4",
];

const ourMissionDescriptions = [
  "our_mission_description_1",
  "our_mission_description_2",
];

const ourVisionDescriptions = [
  "our_vision_description_1",
  "our_vision_description_2",
];

function Home() {
  const { t: translate } = useTranslation();

  const {
    events: {
      numbers,
      partnersData: { data: partnersData },
      producersData: { data: producersData },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  return (
    <div className="font-montserrat">
      <Navbar
        ordersButtonText={translate("navbar_orders")}
        navbarLinks={[
          {
            name: translate("navbar_link_1"),
            href: "home",
          },
          {
            name: translate("navbar_link_2"),
            href: "about",
          },
        ]}
      />

      <div className="h-[55px]" />
      <Element name="home">
        <HeroVideoComponent height={"100vh"} wrapperClassName="mb-[100px]" />
      </Element>

      <AboutCard />

      <Element name="about">
        <VideoAndContentComponent
          title={translate("about_us_title")}
          descriptions={aboutUsDescriptions.map((description, idx) => (
            <p
              key={`description-${idx}`}
              className={"text-sm xl:text-base font-medium  mb-6"}
            >
              {translate(description)}
            </p>
          ))}
          videoUrl={clientConfig.video_url}
        />
      </Element>

      <FullBgWithContentComponent
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0145.jpg")}
        title={translate("our_mission_title")}
        descriptions={ourMissionDescriptions.map((description, idx) => (
          <p
            key={`description-${idx}`}
            className={
              "text-white sm:text-black text-sm xl:text-base font-medium  mb-6"
            }
          >
            {translate(description)}
          </p>
        ))}
      />

      <ImageAndContentComponent
        title={translate("our_vision_title")}
        descriptions={ourVisionDescriptions.map((description, idx) => (
          <p
            key={`description-${idx}`}
            className={"text-sm xl:text-base font-medium  mb-6"}
          >
            {translate(description)}
          </p>
        ))}
        sectionImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0222.jpg")}
      />

      <FullBgWithContentComponent
        moreText
        contentBackground="backdrop-blur-[2px]"
        contentType="secondary"
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0214.jpg")}
        wrapperClassName="hidden lg:flex"
      >
        <CompanyCard
          className="hidden lg:flex bg-green-900 bg-opacity-30"
          companyNumbers={numbers}
        />
      </FullBgWithContentComponent>

      <CompanyCard
        className="lg:hidden flex-wrap bg-green-800 bg-opacity-60 "
        companyNumbers={numbers}
      />

      <div className="flex justify-center mb-[60px] py-2 border-b-2 border-t-2 border-primary-color">
        <img
          src={toAbsoluteUrl("/assets/procto-gyvenol.gif")}
          alt="gif-procto-gyvenol"
        />
      </div>

      <SliderWithText
        className={"border-b-2 border-primary-color"}
        title={translate("partners_title")}
        sliderData={partnersData}
      />

      <HeroComponent
        wrapperClassName="my-2"
        height={300}
        title={translate("hero_title_down_section")}
        backgroundImage={toAbsoluteUrl("/assets/2aFarmPhotos/_SF_0310.jpg")}
      />

      <SliderWithText
        className={"border-t-2 border-primary-color"}
        title={translate("producers_title")}
        sliderData={producersData}
      />
    </div>
  );
}

export default Home;
